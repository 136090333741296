import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ngxPermissionsGuard, NgxPermissionsGuard } from 'ngx-permissions';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { PageToLandscapeComponent } from './core/components/page-to-landscape/page-to-landscape.component';
import { HomePagePreferenceGuard } from './shared/guards/homepage-preference.guard';
import { SupervisionGuard } from './shared/guards/supervision.guard';

const routes: Routes = [
  {
    path: 'homepage',
    component: PageNotFoundComponent,
    canActivate: [HomePagePreferenceGuard],
  },
  {
    path: 'subfolders',
    data: {
      permissions: {
        only: ['ConsultationTD', 'GestionTD'],
        redirectTo: 'supervision/activity',
      },
    },
    canMatch: [ngxPermissionsGuard],
    loadChildren: () =>
      import('./features/subfolders/subfolders.module').then(
        (m) => m.SubfoldersModule
      ),
  },
  {
    path: 'case',
    data: {
      permissions: {
        only: ['AdminTD'],
        redirectTo: 'supervision/activity',
      },
    },
    canMatch: [ngxPermissionsGuard],
    loadChildren: () =>
      import('./features/subfolders-admin/subfolders-admin.module').then(
        (m) => m.SubfoldersAdminModule
      ),
  },
  {
    path: 'supervision',
    data: {
      permissions: {
        only: ['StatistiquesSupervision'],
        redirectTo: 'subscriptions',
      },
    },
    canMatch: [ngxPermissionsGuard, SupervisionGuard],
    loadChildren: () =>
      import('./features/supervision/supervision.module').then(
        (m) => m.SupervisionModule
      ),
  },
  {
    path: 'subscriptions',
    data: {
      permissions: {
        only: [
          'GestionAbonnements',
          'ConsultationAbonnements',
          'AdminAbonnements',
        ],
        redirectTo: 'my-account',
      },
    },
    canMatch: [ngxPermissionsGuard],
    loadChildren: () =>
      import('./features/subscription/subscription.module').then(
        (m) => m.SubscriptionModule
      ),
  },
  {
    path: 'users',
    data: {
      permissions: {
        only: [
          'GestionUtilisateurs',
          'ConsultationUtilisateurs',
          'AdminUtilisateurs',
        ],
      },
    },
    canMatch: [ngxPermissionsGuard],
    loadChildren: () =>
      import('./features/users/users.module').then((m) => m.UsersModule),
  },
  {
    path: 'admin/organization',
    data: {
      permissions: {
        only: ['AdminReferentiel'],
      },
    },
    canMatch: [ngxPermissionsGuard],
    loadChildren: () =>
      import('./features/organization-admin/organization-admin.module').then(
        (m) => m.OrganizationAdminModule
      ),
  },
  {
    path: 'my-account',
    loadChildren: () =>
      import('./features/my-account/my-account.module').then(
        (m) => m.MyAccountModule
      ),
  },
  {
    path: 'contact-us',
    loadChildren: () =>
      import('./features/contact-us/contact-us.module').then(
        (m) => m.ContactUsModule
      ),
  },
  {
    path: 'legal-notice',
    loadChildren: () =>
      import('./features/legal-notice/legal-notice.module').then(
        (m) => m.LegalNoticeModule
      ),
  },
  {
    path: 'accessibility',
    loadChildren: () =>
      import('./features/accessibility/accessibility.module').then(
        (m) => m.AccessibilityModule
      ),
  },
  {
    path: 'faq',
    loadChildren: () =>
      import('./features/faq/faq.module').then((m) => m.FaqModule),
  },
  {
    path: 'notification/delete',
    loadChildren: () =>
      import('./features/notification/notification.module').then(
        (m) => m.NotificationModule
      ),
  },
  { path: '404error', component: PageNotFoundComponent },
  { path: 'to-landscape', component: PageToLandscapeComponent },
  {
    path: '',
    redirectTo: 'homepage',
    pathMatch: 'full',
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
