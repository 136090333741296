import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { NgxPermissionsService } from 'ngx-permissions';
import { PreferencesService } from 'src/app/shared/service/preferences.service';
import { userTypeEnum } from '../../models/userType.enum';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  searchedSubfolder: string;
  isSidebarOpen = true;
  @Output() toggleSidebarEvent: EventEmitter<boolean> = new EventEmitter();
  isSearchBarOpen = false;
  userType = '';
  usertypefrom = '';
  displayedSearchBar = false;
  pageSize: any;
  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private route: ActivatedRoute,
    private permissionsService: NgxPermissionsService,
    private preferencesService: PreferencesService
  ) {}

  ngOnInit(): void {
    this.pageSize = this.preferencesService.getUserPagination();
    const claims: any = this.oauthService.getIdentityClaims();
    this.usertypefrom = claims.user_type;
    switch (this.usertypefrom) {
      case 'ADM_NAT':
        this.userType = userTypeEnum.ADM_NAT;
        break;
      case 'ADM_MET':
        this.userType = userTypeEnum.ADM_MET;
        break;
      case 'ADM_LOC':
        this.userType = userTypeEnum.ADM_LOC;
        break;
      case 'AGT':
        this.userType = userTypeEnum.AGT;
        break;
      case 'DEL_TEC':
        this.userType = userTypeEnum.DEL_TEC;
        break;
    }
    this.replaceOrResetSearchInput();
    this.checkPermissionsUserConnected();
  }
  public onElementSubmitted(value: string): void {
    this.searchedSubfolder = value;
    this.onSearch();
  }

  public onSearch(): void {
    if (this.searchedSubfolder) {
      this.router.navigate(['/subfolders'], {
        relativeTo: this.route,
        queryParams: {
          searchedSubfolder: this.searchedSubfolder,
          pageNumber: 0,
          pageSize: this.pageSize,
          status: 0,
          process: null,
          startSubmittedDate: null,
          endSubmittedDate: null,
          requester: null,
          number: null,
          searched: null,
          sortAttribute: null,
          direction: null,
          processPermission: null,
          userRoles: null,
          userType: null,
          approaches: null,
          statuses: null,
          accessModes: null,
          siret: null,
          orderBy: null,
          ascending: null,
        },
        queryParamsHandling: 'merge',
      });
    }
  }

  public toggleSidebar(): void {
    this.isSidebarOpen = !this.isSidebarOpen;
    this.toggleSidebarEvent.emit(this.isSidebarOpen);
  }
  public toggleSearchBar(): void {
    this.isSearchBarOpen = !this.isSearchBarOpen;
  }
  public replaceOrResetSearchInput(): void {
    this.router.events.subscribe(() => {
      this.route.queryParamMap.subscribe((params) => {
        if (params.get('searchedSubfolder') != null) {
          this.searchedSubfolder = params.get('searchedSubfolder');
        } else {
          this.searchedSubfolder = '';
        }
      });
    });
  }

  public logout(): void {
    this.oauthService.logOut();
  }

  get givenName() {
    const claims: any = this.oauthService.getIdentityClaims();
    if (!claims) {
      return null;
    }
    return `${claims.given_name} ${claims.family_name}`;
  }

  get firstName() {
    const claims: any = this.oauthService.getIdentityClaims();
    if (!claims) {
      return null;
    }
    return claims.given_name;
  }

  get lastName() {
    const claims: any = this.oauthService.getIdentityClaims();
    if (!claims) {
      return null;
    }
    return claims.family_name;
  }

  public navigateTo(searchedSubfolder: string): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        searchedSubfolder,
      },
      queryParamsHandling: 'merge',
    });
  }

  public checkValue(event: any): void {
    if (!event) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          searchedSubfolder: null,
        },
        queryParamsHandling: 'merge',
      });
    }
  }

  public navigateToMyAccount(): void {
    this.router.navigate(['my-account']);
  }

  /**
   * Checks currently connected user permissions.
   *
   * Purpose: Used for search bar availability and switching classes accordingly in HTML.
   */
  private checkPermissionsUserConnected(): void {
    const allowedPermissions: string[] = [
      'AdminTD',
      'GestionTD',
      'ConsultationTD',
    ];
    this.permissionsService.hasPermission(allowedPermissions).then((result) => {
      if (result) {
        this.displayedSearchBar = true;
      }
    });
  }
}
