import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';

const retryCount = 1;

const requestException = [
  {
    method: 'PUT',
    url: 'portail-nhube/users/me/password',
  },
  {
    method: 'POST',
    url: 'portail-nhube/users',
  },
];

@Injectable({
  providedIn: 'root',
})
export class RequestInterceptors implements HttpInterceptor {
  /**
   * Intercepte toutes les requêtes HttpClient
   * et réexécute (une seul fois) la requête en cas d'erreur
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      requestException.some(
        (url) => req.url.endsWith(url.url) && req.method === url.method
      )
    ) {
      return next.handle(req);
    }
    return next.handle(req).pipe(retry(retryCount));
  }
}
