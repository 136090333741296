import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HeaderComponent } from './components/header/header.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { FormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { MenuComponent } from './components/menu/menu.component';
import { FooterComponent } from './components/footer/footer.component';
import { AppRoutingModule } from '../app-routing.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { OidcAuthenticationService } from '../shared/service/oidc-authenthication.service';
import { MatButtonModule } from '@angular/material/button';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PageToLandscapeComponent } from './components/page-to-landscape/page-to-landscape.component';
import { CommonModule } from '@angular/common';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

@NgModule({
  declarations: [
    HeaderComponent,
    MenuComponent,
    FooterComponent,
    PageNotFoundComponent,
    PageToLandscapeComponent,
  ],
  imports: [
    MatSidenavModule,
    MatButtonModule,
    MatListModule,
    AppRoutingModule,
    MatMenuModule,
    FormsModule,
    CommonModule,
    NgxPermissionsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [
    AppRoutingModule,
    HeaderComponent,
    MenuComponent,
    FooterComponent,
    TranslateModule,
    PageNotFoundComponent,
    PageToLandscapeComponent,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory:
        (oidcAuthenticationService: OidcAuthenticationService) => () =>
          oidcAuthenticationService.initAuth(),
      deps: [OidcAuthenticationService],
      multi: true,
    },
  ],
})
export class CoreModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
