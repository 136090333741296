<main
  tabindex="0"
  aria-label="page pivoter écran"
  class="detailed-page-container"
>
  <section class="section__page-header rf-container-fluid">
    <div *ngIf="isSmallScreen; then ScreenTooSmall; else ToLandscape"></div>
    <ng-template
      #ToLandscape
      class="error rf-grid-row"
      title="section pivoter écran"
    >
      <div class="message rf-col-12" aria-labelledby="titre pivoter écran">
        {{ "labels.errors.pageToLandscape.titleToLandscape" | translate }}
      </div>
      <div
        class="message-description rf-col-12"
        aria-labelledby="description pivoter écran"
      >
        {{ "labels.errors.pageToLandscape.descriptionToLandscape" | translate }}
      </div>
      <i role="img" id="toLandscapeIcon" class="fas fa-mobile-alt fa-rotate-90">
      </i>
    </ng-template>
    <ng-template
      #ScreenTooSmall
      class="error rf-grid-row"
      title="section écran trop petit"
    >
      <div class="message rf-col-12" aria-labelledby="titre écran trop petit">
        {{ "labels.errors.pageToLandscape.titleScreenTooSmall" | translate }}
      </div>
      <div
        class="message-description rf-col-12"
        aria-labelledby="description écran trop petit"
      >
        {{
          "labels.errors.pageToLandscape.descriptionScreenTooSmall" | translate
        }}
      </div>
      <a
        aria-labelledby="retour page accueil"
        role="button"
        class="rf-col-12"
        [routerLink]="['/']"
      >
        {{ "labels.errors.buttonHomePage" | translate }}
      </a>
    </ng-template>
  </section>
</main>
