<div class="app-sidebar sidebar-shadow">
  <div class="app-header__logo">
    <div class="logo-src"></div>
    <div class="header__pane ml-auto">
      <div>
        <button
          aria-label="menu"
          role="button"
          type="button"
          class="hamburger close-sidebar-btn hamburger--elastic"
          data-class="closed-sidebar"
        >
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </div>
    </div>
  </div>
  <div class="app-header__mobile-menu">
    <div>
      <button
        role="button"
        aria-label="menu"
        type="button"
        class="hamburger hamburger--elastic mobile-toggle-nav"
      >
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>
  </div>
  <div class="app-header__menu">
    <span>
      <button
        aria-label="menu"
        type="button"
        class="
          btn-icon btn-icon-only btn btn-primary btn-sm
          mobile-toggle-header-nav
        "
      >
        <span class="btn-icon-wrapper">
          <i class="fa fa-ellipsis-v fa-w-6"></i>
        </span>
      </button>
    </span>
  </div>
  <nav
    tabindex="0"
    aria-label="navigation du portail"
    class="scrollbar-sidebar ps"
  >
    <div class="app-sidebar__inner">
      <div
        *ngxPermissionsOnly="['ConsultationTD', 'GestionTD']"
        tabindex="0"
        class="app-sidebar__heading"
        id="MENU-LIBELLE-SECTION"
      >
        {{ "labels.menu.subfolders" | translate }}
      </div>
      <ul
        *ngxPermissionsOnly="['ConsultationTD', 'GestionTD']"
        aria-label="liste statuts des teledossiers"
        tabindex="0"
        role="list"
        class="ul vertical-nav-menu metismenu"
        id="MENU-BT-STAT"
      >
        <li
          role="listitem"
          tabindex="0"
          *ngFor="let td of TDstatus"
          (keydown.enter)="onSelect(td.id)"
        >
          <a role="button" (click)="onSelect(td.id)">
            <i class="metismenu-icon {{ td.statusIcon }}"></i
            ><span>{{ td.statusNamePlural }}</span></a
          >
        </li>
      </ul>
      <div
        tabindex="0"
        *ngxPermissionsOnly="[
          'GestionUtilisateurs',
          'ConsultationUtilisateurs'
        ]"
        class="app-sidebar__heading"
        id="MENU-LIBELLE-SECTION"
      >
        {{ "labels.menu.users" | translate }}
      </div>
      <ng-container
        *ngxPermissionsOnly="[
          'GestionUtilisateurs',
          'ConsultationUtilisateurs'
        ]"
      >
        <ul
          aria-label="contenu section"
          tabindex="0"
          role="list"
          class="ul vertical-nav-menu metismenu"
          id="MENU-BT-GEST-COMPT"
        >
          <li
            role="listitem"
            tabindex="0"
            *ngFor="let item of usersMenuItemsList"
            (keydown.enter)="onSelectUserItem()"
          >
            <a role="button" (click)="onSelectUserItem()">
              <i class="metismenu-icon {{ item.icon }}"></i>{{ item.title }}</a
            >
          </li>
        </ul>
      </ng-container>

      <div
        tabindex="0"
        class="app-sidebar__heading"
        role="navigation"
        *ngxPermissionsOnly="['GestionAbonnements', 'ConsultationAbonnements']"
        id="MENU-LIBELLE-SECTION"
      >
        {{ "labels.menu.subscriptions.title" | translate }}
      </div>
      <ul
        aria-label="contenu section"
        tabindex="0"
        class="ul vertical-nav-menu metismenu"
        *ngxPermissionsOnly="['GestionAbonnements', 'ConsultationAbonnements']"
        id="MENU-BT-GEST-ABONN"
      >
        <li tabindex="0" (keydown.enter)="onSelectSubscriptionItem()">
          <a role="button" (click)="onSelectSubscriptionItem()">
            <i class="metismenu-icon fas fa-hands-helping"></i
            >{{ "labels.menu.subscriptions.management" | translate }}</a
          >
        </li>
      </ul>
      <div *ngIf="isAdminNat()">
        <div
          tabindex="0"
          class="app-sidebar__heading"
          role="navigation"
          *ngxPermissionsOnly="['StatistiquesSupervision']"
        >
          {{ "labels.menu.supervision.title" | translate }}
        </div>
        <ul
          aria-label="contenu section supervision"
          tabindex="0"
          class="ul vertical-nav-menu metismenu"
          *ngxPermissionsOnly="['StatistiquesSupervision']"
        >
          <li tabindex="0" (keydown.enter)="onSelectActivitySupervisionItem()">
            <a role="button" (click)="onSelectActivitySupervisionItem()">
              <i class="metismenu-icon fas fa-chart-line"></i>
              {{ "labels.menu.supervision.activitySupervision" | translate }}
            </a>
          </li>
          <li tabindex="0" (keydown.enter)="onSelectStatsItem()">
            <a role="button" (click)="onSelectStatsItem()">
              <i class="metismenu-icon fas fa-chart-bar"></i>
              {{ "labels.menu.supervision.stats" | translate }}
            </a>
          </li>
          <li tabindex="0" (keydown.enter)="onSelectTechnicalSupervisionItem()">
            <a role="button" (click)="onSelectTechnicalSupervisionItem()">
              <i class="metismenu-icon fas fa-desktop"></i>
              {{ "labels.menu.supervision.technicalSupervision" | translate }}
            </a>
          </li>
        </ul>
      </div>

      <div
        tabindex="0"
        class="app-sidebar__heading"
        role="navigation"
        *ngxPermissionsOnly="['AdminAbonnements', 'AdminUtilisateurs']"
      >
        {{ "labels.menu.administration.title" | translate }}
      </div>
      <ul
        aria-label="contenu section administration"
        tabindex="0"
        class="ul vertical-nav-menu metismenu"
        *ngxPermissionsOnly="['AdminAbonnements', 'AdminUtilisateurs']"
      >
        <li
          tabindex="0"
          (keydown.enter)="onSelectAdminSubscriptionsItem()"
          *ngxPermissionsOnly="['AdminAbonnements']"
        >
          <a role="button" (click)="onSelectAdminSubscriptionsItem()">
            <i class="metismenu-icon fas fa-hands-helping"></i>
            {{ "labels.menu.administration.subscriptions" | translate }}
          </a>
        </li>
        <li
          tabindex="0"
          (keydown.enter)="onSelectAdminUsersItem()"
          *ngxPermissionsOnly="['AdminUtilisateurs']"
        >
          <a role="button" (click)="onSelectAdminUsersItem()">
            <i class="metismenu-icon fas fa-users-cog"></i>
            {{ "labels.menu.administration.users" | translate }}
          </a>
        </li>
      </ul>

      <div
        tabindex="0"
        class="app-sidebar__heading"
        role="navigation"
        *ngxPermissionsOnly="['AdminReferentiel']"
      >
        {{ "labels.menu.repositories.title" | translate }}
      </div>
      <ul
        aria-label="contenu section référentiels"
        tabindex="0"
        class="ul vertical-nav-menu metismenu"
        *ngxPermissionsOnly="['AdminReferentiel']"
      >
        <li tabindex="0" (keydown.enter)="onSelectRepoProceduresItem()" hidden>
          <a role="button" (click)="onSelectRepoProceduresItem()">
            <i class="metismenu-icon fas fa-clipboard"></i>
            {{ "labels.menu.repositories.procedures" | translate }}
          </a>
        </li>
        <li tabindex="0" (keydown.enter)="onSelectRepoOrganizationsItem()">
          <a role="button" (click)="onSelectRepoOrganizationsItem()">
            <i class="metismenu-icon fas fa-network-wired"></i>
            {{ "labels.menu.repositories.organizations" | translate }}
          </a>
        </li>
      </ul>

      <div
        tabindex="0"
        class="app-sidebar__heading"
        role="navigation"
        *ngxPermissionsOnly="['Parametrage']"
      >
        {{ "labels.menu.setting.title" | translate }}
      </div>
      <ul
        aria-label="contenu section paramétrage"
        tabindex="0"
        class="ul vertical-nav-menu metismenu"
        *ngxPermissionsOnly="['Parametrage']"
      >
        <li hidden tabindex="0" (keydown.enter)="onSelectMailNotifItem()">
          <a role="button" (click)="onSelectMailNotifItem()">
            <i class="metismenu-icon fas fa-envelope"></i>
            {{ "labels.menu.setting.mailNotif" | translate }}
          </a>
        </li>
        <li tabindex="0" (keydown.enter)="onSelectInstContentItem()">
          <a role="button" (click)="onSelectInstContentItem()">
            <i class="metismenu-icon fas fa-file-alt"></i>
            {{ "labels.menu.setting.instContent" | translate }}
          </a>
        </li>
      </ul>
    </div>
  </nav>
</div>
