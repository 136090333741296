<footer tabindex="0" aria-label="pied de page portail">
  <div class="app-container app-theme-white body-tabs-shadow fixed-header">
    <div class="app-wrapper-footer">
      <div class="app-footer">
        <div class="app-footer__inner">
          <div class="app-footer-left">
            <div class="nav">
              <a
                tabindex="0"
                aria-label="Condition génerale d'utilisation"
                title="Condition génerale d'utilisation"
                target="_blank"
                href="{{ cguUrl }}"
                class="nav-link nav-item"
                style="color: #1e1e1e; font-weight: bold"
                id="FOOTER-MENT_LEGALES"
              >
                {{ "labels.footer.legalNotice" | translate }}
              </a>
              <a
                tabindex="0"
                aria-label="accessibilité"
                title="accessibilité"
                target="_blank"
                href="{{ accessibiliteUrl }}"
                class="nav-link nav-item"
                style="color: #1e1e1e; font-weight: bold"
                id="FOOTER-ACCESSIBILITE"
              >
                {{ "labels.footer.accessibility" | translate }}
              </a>
            </div>
          </div>
          <div class="app-footer-mid">
            <p id="FOOTER-VERSION-APP" class="version">v.{{ version }}</p>
          </div>
          <div class="app-footer-right">
            <div class="nav">
              <a
                tabindex="0"
                aria-label="Aide"
                title="Aide"
                href="{{ functionalDocUrl }}"
                target="_blank"
                class="nav-link nav-item"
                style="color: #1e1e1e; font-weight: bold"
                id="FOOTER-AIDE"
              >
                {{ "labels.footer.faq" | translate }}
              </a>
              <a
                tabindex="0"
                aria-label="Contactez-nous"
                title="contact"
                [routerLink]="['/contact-us']"
                class="nav-link nav-item"
                style="color: #1e1e1e; font-weight: bold"
                id="FOOTER-CONTACT"
              >
                {{ "labels.footer.contactUs" | translate }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <script
      name="main"
      role="main"
      type="text/javascript"
      src="../../../../assets/scripts/main.js"
    ></script>
  </div>
</footer>
