import { RoutePath } from './routePath';

export enum HomePage {
  subfoldersAll = 'TD - Tous',
  subfoldersNew = 'TD - Nouveaux',
  subfoldersReceived = 'TD - Reçus',
  subfoldersInProgress = 'TD - En cours',
  subfoldersWaiting = 'TD - En attente de compléments',
  subfoldersRejected = 'TD - Refusés',
  subfoldersProcessed = 'TD - Traités',
  subfoldersClosed = 'TD - Clos',
  usersManagement = 'Utilisateurs - Gestion',
  subscriptionsManagement = 'Abonnements - Gestion',
  adminUsersManagement = 'Utilisateurs - Administration',
  adminSubscriptionsManagement = 'Abonnements - Administration',
  supervisionActivity = 'Supervision - Activité',
  supervisionStats = 'Supervision - Statistiques',
  supervisionTechnical = 'Supervision - Technique',
  adminOrganizationsManagement = 'Référentiels - Organisations',
  adminProceduresManagement = 'Référentiels - Démarches',
  adminInstContentManagement = 'Paramétrage - Contenu institutionnel',
}

export interface MatchHomePageRoutePath {
  homePage: HomePage;
  routePath: RoutePath;
}
