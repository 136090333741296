import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { HomePage } from '../models/homePage';
import { RoutePath } from '../models/routePath';

@Injectable({
  providedIn: 'root',
})
export class HomePagePreferenceGuard  {
  constructor(private router: Router, private oAuthService: OAuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const claims: any = this.oAuthService.getIdentityClaims();
    const homePageUserPreferences: string = claims.home_page;
    if (homePageUserPreferences) {
      Object.values(HomePage).forEach((value, index) => {
        if (value === homePageUserPreferences) {
          const enumName = Object.keys(HomePage)[index];
          this.handleRedirection(enumName);
        }
      });
    } else {
      this.router.navigate(['/subfolders']);
    }

    return false;
  }

  private handleRedirection(enumName: string): void {
    const navigationString = String(RoutePath[enumName]).split('?');
    if (navigationString[0] === '/subfolders') {
      const status = navigationString[1].split('=');
      this.router.navigate([navigationString[0]], {
        queryParams: {
          status: status[1],
        },
      });
    } else {
      this.router.navigate([navigationString[0]]);
    }
  }
}
