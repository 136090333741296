import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-page-to-landscape',
  templateUrl: './page-to-landscape.component.html',
  styleUrls: ['./page-to-landscape.component.scss'],
})
export class PageToLandscapeComponent implements OnInit, OnDestroy {
  redirectionFrom: string;
  isSmallScreen: boolean;
  private subscription: Subscription = new Subscription();

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // Get param ''from'' where user was redirected
    this.subscription.add(
      this.route.queryParamMap.subscribe((params: ParamMap) => {
        this.redirectionFrom = params.get('from');
      })
    );

    /* Redirect user from where he was redirected from when screen is large enough to support view */
    const layoutChanges = this.breakpointObserver.observe([
      '(orientation: landscape)',
      '(min-width: 768px)',
    ]);

    this.subscription.add(
      layoutChanges.subscribe((result: BreakpointState) => {
        if (result.breakpoints['(min-width: 768px)']) {
          this.switchToPage();
        } else {
          this.checkScreen();
        }
      })
    );
  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Redirect to page from where user was
   */
  private switchToPage(): void {
    if (this.redirectionFrom === 'users') {
      this.router.navigate(['/users'], { relativeTo: this.route });
    } else if (this.redirectionFrom === 'subscriptions') {
      this.router.navigate(['/subscriptions'], { relativeTo: this.route });
    } else if (this.redirectionFrom === 'adminusers') {
      this.router.navigate(['/users/admin'], { relativeTo: this.route });
    } else if (this.redirectionFrom === 'adminsubscriptions') {
      this.router.navigate(['/subscriptions/admin'], {
        relativeTo: this.route,
      });
    } else {
      this.router.navigate(['/404error'], { relativeTo: this.route });
    }
  }

  /**
   * Check if user screen is too small to support view (Screen is smaller than 767px even in landscape mode)
   */
  private checkScreen(): void {
    const isSmallScreen: boolean =
      this.breakpointObserver.isMatched('(max-width:767px)');
    const isLandscape: boolean = this.breakpointObserver.isMatched(
      '(orientation: landscape)'
    );

    if (isSmallScreen && isLandscape) {
      this.isSmallScreen = true;
    } else {
      this.isSmallScreen = false;
    }
  }
}
