import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { SessionTimeoutBoxComponent } from 'src/app/features/users/modals/session-timeout-dialog-box/session-timeout.component';

const IS_SESSION_EXPIRED_KEY = 'isSessionExpired';

@Injectable({
  providedIn: 'root',
})
export class SessionExpirationService {
  isSessionExpiredSubject = new BehaviorSubject<boolean>(false);

  dialogRef: MatDialogRef<SessionTimeoutBoxComponent>;

  constructor(private dialog: MatDialog) {}

  get isSessionExpired(): Observable<boolean> {
    return this.isSessionExpiredSubject.asObservable();
  }

  get IS_SESSION_EXPIRED_KEY(): string {
    return IS_SESSION_EXPIRED_KEY;
  }

  setIsSessionExpired(value: boolean) {
    this.isSessionExpiredSubject.next(value);
    sessionStorage.setItem(IS_SESSION_EXPIRED_KEY, value.toString());
  }

  openDialog(callBack: Function): void {
    // Evite d'ouvrir plusieurs fois la même boîte de dialog
    if (this.dialogRef == null) {
      // Ouvre la pop-up
      this.dialogRef = this.dialog.open(SessionTimeoutBoxComponent, {
        height: '160px',
        width: '300px',
        autoFocus: false,
        disableClose: true,
        panelClass: 'session-timeout-panel',
      });
      // Si l'utilisateur ferme la pop-up, il sera déconnecté
      this.dialogRef.afterClosed().subscribe(() => {
        //Des que l'utilisateur ferme la modal de timemout, on reset la valeur pour que la session suivante ne soit pas expiree par defaut
        this.setIsSessionExpired(false);
        callBack()
      });
      
    }
  }
}
