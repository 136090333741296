import { Injectable, OnInit } from '@angular/core';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { SessionExpirationService } from './session-expiration.service';

@Injectable({
  providedIn: 'root',
})
export class OidcAuthenticationService implements OnInit {
  defaultUrl: string = window.location.origin;
  isSessionExpired: boolean;

  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private sessionExpirationService: SessionExpirationService
  ) {}

  ngOnInit(): void {
    this.sessionExpirationService.isSessionExpired.subscribe(
      (isSessionExpired) => (this.isSessionExpired = isSessionExpired)
    );
  }

  /**
   * Init authentication
   */
  async initAuth(): Promise<any> {
    return new Promise<void>((resolveFn, rejectFn) => {
      this.oauthService.redirectUri = this.defaultUrl + '/index.html';
      this.oauthService.clientId = environment.clientId;
      this.oauthService.scope = environment.scope;
      this.oauthService.userinfoEndpoint = environment.userinfoEndpoint;
      this.oauthService.oidc = environment.oidc;
      this.oauthService.issuer = environment.issuer;
      this.oauthService.skipIssuerCheck = true;
      this.oauthService.strictDiscoveryDocumentValidation =
        environment.strictDiscoveryDocumentValidation;
      this.oauthService.responseType = 'code';
      this.oauthService.timeoutFactor = 0.99;
      this.oauthService.tokenValidationHandler = new JwksValidationHandler();
      this.oauthService.events.subscribe((event) => {
        if (
          event.type === 'token_expires' ||
          event instanceof OAuthErrorEvent
        ) {
          if (!this.isSessionExpired) {
            this.isSessionExpired = true;
            this.sessionExpirationService.setIsSessionExpired(true);
            this.sessionExpirationService.openDialog(() => {
              this.oauthService.logOut();
            });
          }
        }
      });
      this.oauthService.loadDiscoveryDocumentAndLogin().then((isLoggedIn) => {
        if (isLoggedIn) {
          this.oauthService.setupAutomaticSilentRefresh(); // does not work since not setup in WSO2IS
          resolveFn();
        } else {
          this.oauthService.initImplicitFlow(
            encodeURIComponent(this.router.url)
          );
          rejectFn();
        }
      });
    });
  }
}
