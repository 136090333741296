<main tabindex="0" aria-label="page d'erreur" class="detailed-page-container">
  <section class="section__page-header rf-container-fluid">
    <div class="error rf-grid-row" title="section d'erreur 404">
      <div class="message rf-col-12" aria-labelledby="titre page erreur">
        {{ "labels.errors.pageNotFound.title" | translate }}
      </div>
      <div
        class="message-description rf-col-12"
        aria-labelledby="description page erreur"
      >
        {{ "labels.errors.pageNotFound.description" | translate }}
      </div>
      <a
        aria-labelledby="retour page accueil"
        role="button"
        class="rf-col-12"
        [routerLink]="['/']"
      >
        {{ "labels.errors.buttonHomePage" | translate }}
      </a>
    </div>
  </section>
</main>
