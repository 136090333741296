import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import packageInfo from '../../../../../package.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  public version: string = packageInfo?.version;
  functionalDocUrl: string;

  cguUrl: string;
  accessibiliteUrl: string;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.cguUrl = environment.cguUrl;
    this.accessibiliteUrl = environment.accessibiliteUrl;
    this.functionalDocUrl = environment.functionalDocUrl;
  }
}
