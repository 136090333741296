{
  "name": "nhube",
  "version": "3.1.0-5",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --disable-host-check",
    "build": "ng build --output-hashing=all --base-href ./",
    "test": "ng test",
    "test-debug": "ng test --detect-open-handles --watch",
    "lint": "ng lint",
    "sonar": "sonar-scanner",
    "prettier": "prettier --no-config --write \"./src/**/*.{html,css,scss,json}\"",
    "prettierts": "prettier --no-config --write \"./src/**/*.ts\"",
    "tslintfix": "tslint --fix --project tsconfig.json \"./src/**/*.ts\" -e \"./src/environments/**/*.ts\""
  },
  "private": true,
  "dependencies": {
    "@angular-material-components/datetime-picker": "^16.0.1",
    "@angular-material-components/moment-adapter": "^16.0.1",
    "@angular/animations": "^18.0.5",
    "@angular/cdk": "^18.0.5",
    "@angular/common": "^18.0.5",
    "@angular/compiler": "^18.0.5",
    "@angular/core": "^18.0.5",
    "@angular/forms": "^18.0.5",
    "@angular/material": "^18.0.5",
    "@angular/material-moment-adapter": "^18.0.5",
    "@angular/platform-browser": "^18.0.5",
    "@angular/platform-browser-dynamic": "^18.0.5",
    "@angular/router": "^18.0.5",
    "@fortawesome/angular-fontawesome": "^0.11.1",
    "@fortawesome/fontawesome-svg-core": "^1.2.35",
    "@fortawesome/free-regular-svg-icons": "^5.15.3",
    "@fortawesome/free-solid-svg-icons": "^5.15.3",
    "@gouvfr/dsfr": "^1.12.1",
    "@ng-idle/core": "^15.0.0",
    "@ng-idle/keepalive": "^15.0.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "angular-oauth2-oidc": "^17.0.2",
    "angular-oauth2-oidc-jwks": "^13.0.1",
    "bn-ng-idle": "^2.0.1",
    "bootstrap": "^4.6.0",
    "classlist.js": "^1.1.20150312",
    "core-js": "^3.12.1",
    "file-saver": "^2.0.5",
    "focus-within-polyfill": "^5.2.1",
    "font-awesome": "^4.7.0",
    "jquery": "^3.6.0",
    "material-icons": "^0.3.1",
    "moment": "^2.29.4",
    "ng2-cookies": "^1.0.12",
    "ngx-logger": "^5.0.12",
    "ngx-permissions": "^17.1.0",
    "popper.js": "^1.16.1",
    "rxjs": "^7.8.1",
    "schematics-scss-migrate": "^1.3.13",
    "tslib": "^1.10.0",
    "typescript": "^5.4.5",
    "web-animations-js": "^2.3.2",
    "xlsx": "^0.18.2",
    "zone.js": "^0.14.7"
  },
  "devDependencies": {
    "@angular-builders/jest": "^18.0.0",
    "@angular-devkit/build-angular": "^18.0.6",
    "@angular/cli": "^18.0.6",
    "@angular/compiler-cli": "^18.0.5",
    "@types/file-saver": "^2.0.2",
    "@types/jest": "^27.5.2",
    "@types/node": "^12.20.13",
    "chromium": "^3.0.2",
    "codelyzer": "~6.0.2",
    "jest": "^29.7.0",
    "jest-environment-jsdom": "^29.7.0",
    "jest-preset-angular": "^14.1.1",
    "jest-sonar-reporter": "^2.0.0",
    "prettier": "^2.3.0",
    "sonar-scanner": "^3.1.0",
    "sonarqube-scanner": "^2.8.2",
    "ts-node": "~8.3.0",
    "tslint": "~6.1.0",
    "tslint-config-prettier": "^1.18.0"
  },
  "jestSonar": {
    "reportPath": "reports",
    "reportFile": "ut_report.xml",
    "indent": 4
  }
}
