import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  HttpClient,
  HTTP_INTERCEPTORS,
  provideHttpClient,
} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatGridListModule } from '@angular/material/grid-list';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { DatePipe, registerLocaleData } from '@angular/common';
import { SubfoldersModule } from './features/subfolders/subfolders.module';
import localeFr from '@angular/common/locales/fr';
import { CoreModule } from './core/core.module';
import { OAuthModule } from 'angular-oauth2-oidc';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomMatPaginatorIntl } from './shared/utils/custom-mat-paginator-int';
import { NgxPermissionsModule } from 'ngx-permissions';
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import {
  faFileImage,
  faFileWord,
  faFilePdf,
  faFileCode,
} from '@fortawesome/free-regular-svg-icons';
import { RequestInterceptors } from './shared/interceptors/request-interceptors';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { GetAllSubFolders } from './features/users/modals/get-all-subfolders-dialog-box/get-all-subfolders.component';
import { MatNativeDateModule } from '@angular/material/core';

registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [AppComponent, GetAllSubFolders],
  bootstrap: [AppComponent],
  exports: [TranslateModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    NgIdleKeepaliveModule.forRoot(),
    BrowserModule,
    FontAwesomeModule,
    AppRoutingModule,
    SubfoldersModule,
    FormsModule,
    MatTableModule,
    MatIconModule,
    NgxPermissionsModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatGridListModule,
    CoreModule,
    OAuthModule.forRoot(),
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  providers: [
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptors, multi: true },
    DatePipe,
    provideHttpClient(),
  ],
})
export class AppModule {
  constructor(private library: FaIconLibrary) {
    library.addIcons(faFileImage, faFileWord, faFilePdf, faFileCode);
  }
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
