export enum RoutePath {
  subfoldersAll = '/subfolders?status=0',
  subfoldersNew = '/subfolders?status=2',
  subfoldersReceived = '/subfolders?status=3',
  subfoldersInProgress = '/subfolders?status=4',
  subfoldersWaiting = '/subfolders?status=5',
  subfoldersRejected = '/subfolders?status=6',
  subfoldersProcessed = '/subfolders?status=7',
  subfoldersClosed = '/subfolders?status=8',
  usersManagement = '/users',
  subscriptionsManagement = '/subscriptions',
  adminUsersManagement = '/users/admin',
  adminSubscriptionsManagement = '/subscriptions/admin',
  supervisionActivity = '/supervision/activity',
  supervisionStats = '/supervision/stats',
  supervisionTechnical = '/supervision/technical',
  adminOrganizationsManagement = '/admin/organization',
  adminProceduresManagement = '/',
  adminInstContentManagement = '/',
}
