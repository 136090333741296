import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { statusesList } from 'src/app/shared/models/subfolder';
import { userMenuItems } from 'src/app/features/users/models/userMenuItem';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { PreferencesService } from 'src/app/shared/service/preferences.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  isMenuOpen = true;
  contentMargin = 240;
  TDstatus = statusesList;
  usersMenuItemsList = userMenuItems;
  pageSize: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private oauthService: OAuthService,
    private translate: TranslateService,
    private preferencesService: PreferencesService
  ) {}

  ngOnInit(): void {
    this.pageSize = this.preferencesService.getUserPagination();
  }

  public onSelect(chosenStatus: number): void {
    this.router.navigate(['/subfolders'], {
      queryParams: {
        status: chosenStatus,
        pageNumber: 0,
        pageSize: this.pageSize,
      },
    });
  }
  public onSelectUserItem(): void {
    const path = '/users';
    this.router.navigate([path], {
      queryParams: { pageNumber: 0, pageSize: this.pageSize },
    });
  }

  public onSelectSubscriptionItem(): void {
    const path = '/subscriptions';
    this.router.navigate([path]);
  }

  public onSelectActivitySupervisionItem(): void {
    const path = '/supervision/activity';
    this.router.navigate([path]);
  }

  public onSelectStatsItem(): void {
    const path = '/supervision/stats';
    this.router.navigate([path]);
  }

  public onSelectTechnicalSupervisionItem(): void {
    const path = '/supervision/technical';
    this.router.navigate([path]);
  }

  public onSelectAdminSubscriptionsItem(): void {
    const path = '/subscriptions/admin';
    this.router.navigate([path]);
  }

  public onSelectAdminUsersItem(): void {
    const path = '/users/admin';
    this.router.navigate([path], {
      queryParams: { pageNumber: 0, pageSize: this.pageSize },
    });
  }

  public onSelectRepoProceduresItem(): void {
    const path = '/';
    this.router.navigate([path]);
  }

  public onSelectRepoOrganizationsItem(): void {
    const path = '/admin/organization';
    this.router.navigate([path]);
  }

  public onSelectMailNotifItem(): void {
    // TODO: Change route
    const path = '/';
    this.router.navigate([path]);
  }

  public onSelectInstContentItem(): void {
    const path = '/';
    this.router.navigate([path]);
  }

  public resetQueryParams(path: string, subfolderStatus: number): void {
    this.router.navigate([path], {
      relativeTo: this.route,
      queryParams: {
        status: null,
        process: null,
        startSubmittedDate: null,
        endSubmittedDate: null,
        requester: null,
        number: null,
        pageNumber: null,
        pageSize: null,
        searchedSubfolder: null,
        searched: null,
        sortAttribute: null,
        direction: null,
        processPermission: null,
        userRoles: null,
        userType: null,
        siret: null,
        branchCode: null,
        type: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  onToolbarMenuToggle(): void {
    this.isMenuOpen = !this.isMenuOpen;

    if (!this.isMenuOpen) {
      this.contentMargin = 70;
    } else {
      this.contentMargin = 240;
    }
  }

  isAdminNat(): boolean {
    const claims: any = this.oauthService.getIdentityClaims();
    if (claims) {
      return claims.user_type === 'ADM_NAT';
    }
    return false;
  }
}
