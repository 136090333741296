<div
  [ngClass]="{
    'closed-sidebar': isClosingSidebar,
    'sidebar-mobile-open': isClosingSidebar
  }"
  class="
    app-container app-theme-white
    body-tabs-shadow
    fixed-sidebar fixed-header
  "
>
  <app-header
    (toggleSidebarEvent)="onToggleSidebarClicked($event)"
  ></app-header>
  <div class="app-main">
    <app-menu></app-menu>
    <div class="app-main__outer">
      <div class="app-main__inner">
        <div class="container" style="min-height: 800px">
          <router-outlet></router-outlet>
        </div>
      </div>
      <app-footer></app-footer>
    </div>
  </div>
</div>
